






























import { Vue, Component, Prop } from "vue-property-decorator";
import adminService from "@/services/AdminService";
import { organisationModule } from "@/store/modules/OrganisationModule";

@Component
export default class Organisations extends Vue {
  public organisations: any = [];

  public async selectOrganisation(organisation: any) {
    await organisationModule.dispatchLoad({ organisationId: organisation.id });

    if (organisationModule.organisationDetails)
      this.$router.push("/organisation");
  }

  public async mounted() {
    this.organisations = await adminService.getOrganisations();
  }
}
